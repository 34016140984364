<template>
  <Dialog header="Settings" v-model:visible="enabled" modal dismissableMask :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}">
    <Accordion :activeIndex="0">
      <AccordionTab header="Theme">
        <div class="formgrid grid">
          <div class="field col-12">
            <label class="mr-2">Color Mode</label>
            <Dropdown class="inputfield" v-model="colorModeSelected" :options="colorModeOptions" optionLabel="name" optionValue="value"/>
          </div>
          <!--
          <div class="field col-4 field-checkbox">
            <label class="mr-2">Ascending</label>
            <Checkbox class="inputfield" v-model="colorAscending" :binary="true"/>
          </div> -->
          <div class="field col-12" v-if="colorModeSelected == 'interpolate'">
            <label class="mr-2">Interpolate 1</label>
            <ColorPicker v-model="color1" />
          </div>
          <div class="field col-12" v-if="colorModeSelected == 'interpolate'">
            <label class="mr-2">Interpolate 2</label>
            <ColorPicker v-model="color2" />
          </div>
        </div>
      </AccordionTab>
    </Accordion>
    <template #footer>
      <Button class="right-0" :disabled="checkUpdate" label="Update" @click="saveSettings" />
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Dropdown from 'primevue/dropdown';
import ColorPicker from 'primevue/colorpicker';
import Button from 'primevue/button';
import { mapActions, mapState } from 'vuex';

export default {
  components: { Dialog, Accordion, Button, AccordionTab, Dropdown, ColorPicker },

  props: ["show"],

  computed: {
    ...mapState('colors', ['colorMode', 'colorAsc', 'interpolate1', 'interpolate2']),
  
    checkUpdate() {
      if(this.colorMode != this.colorModeSelected || this.interpolate1 != this.color1 || this.interpolate2 != this.color2) {
        return false;
      }
      return true;
    }
  },

  methods: {
    ...mapActions('colors', ['setColorSettings']),

    saveSettings() {
      this.setColorSettings({
        mode: this.colorModeSelected,
        asc: true, //this.colorAscending,
        interpolate: [this.color1, this.color2],
      });
    }
  },

  watch: {
    show() {
      this.enabled = true;
    }
  },

  data() {
    return {
      enabled: false,
      colorModeSelected: null,
      // colorAscending: false,
      color1: '#ffffff',
      color2: '#000000',
      colorModeOptions: [
        { name: 'Random', value: 'random'},
        { name: 'Interpolate', value: 'interpolate'},
      ]
    }
  },

  mounted() {
    this.colorModeSelected = this.colorMode;
    // this.colorAscending = this.colorAsc;
    this.color1 = this.interpolate1;
    this.color2 = this.interpolate2;
  },

}
</script>

<style>

</style>