<template>
  <div class="h-full w-full absolute" style="background: #000a" v-if="getLoadingInfo">
    <div class="flex justify-content-center align-items-center h-full">
      <div class="flex flex-column p-6 justify-content-center align-items-center" style="width: 600px;">
        <ProgressSpinner strokeWidth="5"/>
        <p class="text-primary">{{ getLoadingInfo.text }}</p> 
      </div>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import { mapGetters } from 'vuex';

export default {
  components: { ProgressSpinner },
  computed: {
    ...mapGetters('general', ['getLoadingInfo']),
  }
}
</script>

<style>

</style>