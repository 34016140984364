<template>
  <Mesh @click="selectElement" ref="mesh">
    <BufferGeometry ref="geometry" :attributes="getAttributes(obj.Pbi3DGeometry.PointsArray)">
    </BufferGeometry>
    <LambertMaterial ref="material" :props="{ side: DoubleSide }"/>
  </Mesh>
</template>

<script>
import { mapActions } from 'vuex';
import { Color, DoubleSide } from 'three';
export default {
  
  props: ["obj", "initialColor"],

  emits: ["clickedElement"],

  computed: {
    DoubleSide() {
      return DoubleSide;
    },
  },
  methods: {
    ...mapActions('general', ['addGeometry']),

    getAttributes(pos) {
      return [ {
        name: "position",
        array: new Float32Array(pos),
        itemSize: 3,
      }];
    },
    selectElement(ev) {
      this.$emit('clickedElement', ev, this.obj)
    },
  },
  data() {
    return {
      geometry: null,
      color: null,
    }
  },
  mounted() {
    this.geometry = this.$refs.geometry.geometry;
    this.geometry.computeVertexNormals();
    this.geometry.computeBoundingBox();
    if(this.obj.geometry){
      this.$refs.mesh.mesh.position.copy(this.obj.geometry.mesh.position.clone());
    }
    this.$refs.mesh.mesh.material.color = new Color(this.initialColor);
    this.$refs.mesh.mesh.userData.id = this.obj.ID;
    this.addGeometry({ id: this.obj.ID, geometry: this.$refs.mesh});
  },
  updated() {
    this.geometry.computeVertexNormals();
    this.geometry.computeBoundingBox();
  }
}
</script>

<style>

</style>