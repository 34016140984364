<template>
<div class="text-primary w-full flex flex-column align-items-start p-2">
  <div class="field"><label>{{getSelectedElements[0] ? getSelectedElements[0].Name : 'No element selected'}}</label></div>
  <div class="field w-14rem">
    <label for="temp">Temperature: {{sliderValue[0] + '°C - '+sliderValue[1]}}°C</label>
    <Slider id="temp" v-model="sliderValue" :range="true" :step="0.5" :min="18" :max="24"/>
  </div>
  <div class="field-checkbox my-3">
    <InputSwitch id="power" v-model="powerMode" />
    <label for="power" class="">Power Save</label>
  </div>

  <div class="field w-full my-3">
    <Chart class="text-primary" type="line" :data="basicData" />
  </div>

</div>

</template>

<script>
import { mapGetters } from 'vuex';

import Slider from 'primevue/slider';
import InputSwitch from 'primevue/inputswitch';
import Chart from 'primevue/chart';

export default {

  components: { Slider, InputSwitch, Chart },

  computed: {
    ...mapGetters('general', ['getSelectedElements']),
  },

  data() {
    return {
      sliderValue: [20,22],
      powerMode: true,
      basicData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September'],
        datasets: [
            {
                label: 'Temperature',
                data: [21.5, 22, 19, 21, 20, 20, 19, 19, 22],
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            },
        ]
      },
    }
  }
}
</script>

<style>

</style>