import { Vector2, Vector3 } from 'three';

const geometriesToMinMax = (geometries) => {
  
  let minVector = new Vector3(Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER,Number.MAX_SAFE_INTEGER);
  let maxVector = new Vector3(Number.MIN_SAFE_INTEGER,Number.MIN_SAFE_INTEGER,Number.MIN_SAFE_INTEGER);

  geometries.forEach(geometry => {
    let bound = geometry.geometry.boundingBox;
    minVector.min(bound.min.clone().add(geometry.mesh.position));
    maxVector.max(bound.max.clone().add(geometry.mesh.position));
  })
  return {
    min: minVector,
    max: maxVector,
  }
}

const Pbi2DGeometryToMinMax = (geometries) => {
  let minVector = new Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
  let maxVector = new Vector2(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
  geometries.forEach(el => {
    if(el.PbiType == "KobiRectangle") {
      let points = el.Points[0];
      minVector.min(new Vector2(points[0]-el.Length/2, points[1]-el.Width/2));
      maxVector.max(new Vector2(points[0]+el.Length/2, points[1]+el.Width/2));
    } else if(el.PbiType == "KobiPolygon") {
      el.Points.forEach(point => {
        minVector.min(new Vector2(point[0], point[1]));
        maxVector.max(new Vector2(point[0], point[1]));
      })
    } else if(el.PbiType == "KobiSphere") {
      let point = el.Points[0];
      minVector.min(new Vector2(point[0]-el.Diameter, point[1]-el.Diameter))
      maxVector.max(new Vector2(point[0]-el.Diameter, point[1]+el.Diameter));
    }
  });
  return {
    min: minVector,
    max: maxVector,
  };
}

export {geometriesToMinMax, Pbi2DGeometryToMinMax}; 