<template>
  <div class="aside">
    <ToggleButton @change="toggleMode" v-model="selectedMode" onIcon="pi pi-pencil" offIcon="pi pi-eye" />
    </div>
</template>

<script>
import ToggleButton from 'primevue/togglebutton';
import { mapActions } from 'vuex';

export default {
  components: { ToggleButton },
  
  methods: {
    ...mapActions('settings', ['changeEditMode']),

    toggleMode() {
      this.changeEditMode(this.selectedMode);
    },
  },

  data() {
    return {
      selectedMode: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
  position: absolute;
  padding: 5px;
  display: flex;
  flex-direction: column;
  .p-component {
    margin: 3px;
  }
}


</style>