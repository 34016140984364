import { createStore } from "vuex";

import general from './general.store';
import colors from './colors.store';
import view2D from "./view2D.store";
import view3D from "./view3D.store";
import settings from "./settings.store";

export default createStore({
  modules: {
    general,
    colors,
    view2D,
    view3D,
    settings,
  },
});
