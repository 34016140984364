<template>
  <Toolbar class="w-full p-1 text-primary">
    <template #left>
      <div>
        <p class="m-0">Selection Mode</p>
        <SelectButton v-model="selectedMode" :options="selectModeOptions" optionLabel="label" optionValue="value"/>
      </div>
    </template>
    <template #right>
      <Button @click="changeSelected([])" label="Clear"/>
    </template>
  </Toolbar>
  <p v-if="getSelectedElements.length == 0" class="text-primary">Click an element, or ctrl click for multiple</p>
  <DataView :value="getSelectedElements" layout="list" :paginator="getSelectedElements.length > 10" :rows="10" class="w-full">
    <template #list="listData">
      <Panel class="w-full panel" :header="listData.data.ID+''" :collapsed="getSelectedElements.length > 5" toggleable>
        <DataTable :value="getProperties(listData.data)" class="p-datatable-sm" :scrollable="true" scrollHeight="flex" :resizableColumns="true" columnResizeMode="fit | expand">
          <template #empty>
            Properties not available
          </template>
          <Column header="Property" field="property"></Column>
          <Column header="Value" field="value">
            <template #body="slotProps">
              <img v-if="isImage(slotProps.data.value)" style="width: 100%;" :src="slotProps.data.value">
              <td class="cell" v-else>{{ slotProps.data.value }}</td>
            </template>
          </Column>
        </DataTable>
      </Panel>
    </template>
  </DataView>
</template>

<script>

import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Column from 'primevue/column';
import Panel from 'primevue/panel';
import Toolbar from 'primevue/toolbar';
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import { mapActions, mapGetters } from 'vuex'

export default {

  components: { DataTable, Column, Panel, Toolbar, SelectButton, Button, DataView },
  computed: {
    ...mapGetters("general", ["getSelectedElements"]),
    ...mapGetters("settings", ["getSelectionMode"],)
  },

  watch: {
    selectedMode(val) {
      this.changeSelectionMode(val);
    }
  },

  methods: {
    ...mapActions('settings', ['changeSelectionMode']),
    ...mapActions('general', ["changeSelected"]),

    getKeys(el) {
      return Object.keys(el).filter(x => !this.blacklistedKeys.includes(x))
    },

    getProperties(el) {
      return this.getKeys(el).map((x) => { return { property: x, value: el[x]}});
    },

    isImage(text) {
      let regex = /(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))/;
      return (""+text).match(regex);
    },
  },
  data() {
    return {
      blacklistedKeys: ["Pbi2DGeometry","Pbi3DGeometry", "geometry", "TempPbi2DGeometry", "initialPosition"],
      selectModeOptions: [
        { label: 'None', value: 'none'},
        { label: 'Single', value: 'single'},
        { label: 'Multiple', value: 'multiple'},
        { label: 'Area', value: 'area'},
      ],
      selectedMode: 'none',
    }
  },
  mounted() {
    this.selectedMode = this.getSelectionMode;
  }
  }
</script>

<style>
.panel .p-panel-content {
  padding: 0 !important;
}
.p-button.p-highlight {
  background: var(--primary-color) !important;
}
</style>
<style scoped>
.p-datatable {
  width: 100%;
}
.cell {
  overflow: auto;
}
</style>