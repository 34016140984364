<template>
  <Aside />
  <Renderer ref="renderer" resize orbitCtrl antialias>
    <Camera ref="camera" :position="getCameraPosition" :far="10000" />
    <Scene ref="scene">
      <DirectionalLight
        color="#ffffff"
        :intensity="0.3"
        :position="{ x: 130, y: 0, z: 100 }"
      />
      <DirectionalLight
        color="#ffffff"
        :intensity="0.3"
        :position="{ x: -100, y: -130, z: 100 }"
      />
      <DirectionalLight
        color="#ffffff"
        :intensity="0.3"
        :position="{ x: -100, y: 130, z: 100 }"
      />
      <AmbientLight :intensity="0.6" />
      <CustomObjectWrapper v-if="getJson"/>
      <Selection :scene="scene" :camera="camera" :renderer="renderer" :orbit="orbitCtrl"/>
    </Scene>
  </Renderer>
  <SelectionArea :scene="scene" :camera="camera" :renderer="renderer" v-if="renderer"/>
</template>

<script>
import { Object3D, Vector3 } from "three";
import CustomObjectWrapper from "./3DComponents/CustomObjectWrapper.vue";
import Selection from "./3DComponents/Selection.vue";
import SelectionArea from "./3DComponents/SelectionArea.vue";
import Aside from "./3DComponents/Aside.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  components: { CustomObjectWrapper, Aside, Selection, SelectionArea },

  computed: {
    ...mapGetters('view3D', ['getCameraPosition', 'getTargetPosition']),
    ...mapGetters('general', ["getJson"]),
    ...mapGetters('settings', ['getSelectionMode', 'isEditMode']),
  },

  watch: {
    getTargetPosition(newVal) {
      if(newVal) {
        this.orbitCtrl.target = newVal;
        this.orbitCtrl.update();
      }
    },
    getSelectionMode() {
      this.handleOrbit();
    },
  },

  methods: {
    ...mapActions('view3D', ['saveCameraPosition', 'saveTargetPosition']),

    handleOrbit() {
      if(this.getSelectionMode == 'area' || this.isEditMode) {
        this.orbitCtrl.enabled = false;
      } else {
        this.orbitCtrl.enabled = true;
      }
    }
  },
  data() {
    return {
      scene: null,
      orbitCtrl: null,
      renderer: null,
      camera: null,
      control: null,
    };
  },
  created() {
    Object3D.DefaultUp.set(0, 0, 1);
  },
  mounted() {
    this.orbitCtrl = this.$refs.renderer.three.cameraCtrl;
    this.scene = this.$refs.scene.scene;
    this.camera = this.$refs.camera.camera;
    this.renderer = this.$refs.renderer.renderer;
    this.renderer.setClearColor(0xeeeeee, 0.2);
    this.camera.up = new Vector3(0, 0, 1);

    this.orbitCtrl.target = this.getTargetPosition;
    this.orbitCtrl.update();

    this.handleOrbit();

  },
  beforeUnmount() {
    this.saveCameraPosition(this.camera.position);
  }
};
</script>

<style>
</style>