<template>
  <Menubar :model="menuItems">
    <template #end>
      <div class="flex align-items-center">
        <span class="mx-2">{{ mode ? "3D" : "2D" }}</span>
        <InputSwitch @input="changedMode" v-model="mode" />
        <FilterTree class="ml-2" />
        <Button
          class="ml-4"
          icon="pi pi-cog"
          @click="openedSettings = !openedSettings"
        />
      </div>
    </template>
  </Menubar>
  <SettingsModal :show="openedSettings" />
  <input
    class="fileInput"
    type="file"
    ref="file"
    @change="loadNew"
    accept=".json, .xlsx"
  />
</template>

<script>
import Menubar from "primevue/menubar";
import Button from "primevue/button";
import SettingsModal from "../Modals/SettingsModal.vue";
import FilterTree from "./FilterTree.vue";
import { mapActions } from "vuex";
const XLSX = require("xlsx");

import gzipToJson from "../../helpers/converters";
import InputSwitch from "primevue/inputswitch";

export default {
  name: "MenuBar",

  components: { Menubar, Button, SettingsModal, InputSwitch, FilterTree },

  computed: {
    mode() {
      return this.$route.name == "3D";
    },
  },

  methods: {
    ...mapActions("general", ["loadJson", "changeLoadingInfo"]),
    ...mapActions("settings", ["changeEditMode"]),

    changedMode(val) {
      this.changeEditMode(false);
      if (val) {
        this.$router.replace({ name: "3D" });
      } else {
        this.$router.replace({ name: "2D" });
      }
    },

    loadFromFile(file) {
      let ext = file.name.split(".").at(-1);
      let vue = this;
      if (ext == "xlsx") {
        var reader = new FileReader();
        reader.onload = function (e) {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          let json = XLSX.utils.sheet_to_json(worksheet);
          vue.loadFromJson(json);
        };
        reader.readAsArrayBuffer(file);
      } else if (ext == "json") {
        let reader = new FileReader();
        reader.onload = (e) => {
          let json = JSON.parse(e.target.result);
          vue.loadFromJson(json["Kobi Toolkit for Power BI"]);
        };
        reader.readAsText(file);
      }
    },

    loadFromJson(json) {
      this.changeLoadingInfo({text: "Loading...", value: 0});
      this.$nextTick(() => {
        let vue = this;
        let elements = [];
        let rand = Math.random().toString(36).substring(7);
        json.forEach((element, index) => {
          try {
            let tmp = { ...element, troisIndex: rand + index};
            if (element.Pbi3DGeometry) {
              let decoded = gzipToJson(element.Pbi3DGeometry);
              if (decoded) {
                tmp = { ...tmp, Pbi3DGeometry: decoded };
              }
            }
            if (element.Pbi2DGeometry) {
              let decoded = gzipToJson(element.Pbi2DGeometry);
              if (decoded) {
                decoded.Points.forEach((point, index) => {
                  decoded.Points[index] = point.Point.split(";").map(Number);
                });
                tmp = { ...tmp, Pbi2DGeometry: decoded };
              }
            }
            if (tmp.Pbi3DGeometry || tmp.Pbi2DGeometry) {
              elements.push(tmp);
            }
          } catch (err) {
            //
          }
        });
        vue.loadJson(elements);
      });
    },

    loadNew(event) {
      if (event.target.files.length == 0) return;
      this.changeLoadingInfo({ text: "Loading...", value: 0 });
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.loadFromFile(event.target.files[0]);
        });
      });
    },
  },

  data() {
    return {
      openedSettings: false,
      menuItems: [
        {
          label: "File",
          icon: "pi pi-fw pi-file",
          items: [
            {
              label: "Import",
              icon: "pi pi-fw pi-upload",
              command: () => {
                this.$refs.file.click();
              },
            },
            {
              label: "Samples",
              icon: "pi pi-fw pi-book",
              items: [
                {
                  label: "Basic",
                  command: () => {
                    import("../../assets/samples/basic.js").then((module) =>
                      this.loadFromJson(module.default)
                    );
                  },
                },
                {
                  label: "Advanced-200",
                  command: () => {
                    import("../../assets/samples/advanced-200.js").then(
                      (module) => this.loadFromJson(module.default)
                    );
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.p-menubar {
  border-radius: 0;
  height: 64px !important;
}
.fileInput {
  position: absolute;
  visibility: hidden;
  z-index: -1;
}
</style>