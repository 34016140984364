<template>
<g :class="{ selected: isSelected }">
  <circle :cx="getX" :cy="getY" :r="getRadius" :style="{fill: colorFill}"/>
</g>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: ['data'],
  events: ['click'],

  computed: {
    ...mapState('general', ['group', 'grouping']),
    ...mapGetters('general', ['getSelectedElements']),
    
    getX() {
      return this.getPoints[0];
    },
    getY() {
      return this.getPoints[1];
    },
    getRadius() {
      return this.data.TempPbi2DGeometry.Radius;
    },
    getPoints() {
      return this.data.TempPbi2DGeometry.Points[0];
    },

    isSelected() {
      return this.getSelectedElements.find(x => x.ID == this.data.ID);
    },
  },

  methods: {
    getColor() {
      if(this.group)
        return this.grouping.find(x => x.group == this.data[this.group]).color;
      return 'aaaaaa'
    },

    setColor() {
      this.colorFill = '#' + this.getColor();
    },
  },
  
  watch: {
    group() {
      this.setColor();
    },
    grouping() {
      this.setColor();
    },
  },

  data() {
    return {
      container: null,
      colorFill: '#aaa',
    }
  },

  mounted() {
    this.setColor();
  },
}
</script>

<style>
.selected {
  stroke: yellow;
  stroke-width: 1;
}
</style>