<template>
<g :class="{ selected: isSelected }">
  <path :d="getPoints" :style="{fill: colorFill }" />
</g>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import * as d3 from 'd3';
const line = d3.line().curve(d3.curveLinearClosed);
      
export default {
  props: ['data'],

  computed: {
    ...mapState('general', ['group', 'grouping']),
    ...mapGetters('general', ['getSelectedElements']),
    
    getPoints() {
      return line(this.data.TempPbi2DGeometry.Points);
    },

    isSelected() {
      return this.getSelectedElements.find(x => x.ID == this.data.ID);
    },
  },

  methods: {
    getColor() {
      if(this.group)
        return this.grouping.find(x => x.group == this.data[this.group]).color;
      return 'aaaaaa'
    },

    setColor() {
      this.colorFill = '#' + this.getColor();
    },
  },
  
  watch: {
    group() {
      this.setColor();
    },
    grouping() {
      this.setColor();
    },
  },

  data() {
    return {
      container: null,
      colorFill: '#aaa',
    }
  },

  mounted() {
    this.setColor();
  },
}
</script>

<style>
.selected {
  stroke: yellow;
  stroke-width: 1;
}
</style>