<template>
  <TreeSelect ref="tree" :modelValue="getFilterTree" :options="getFilteredTreeNodes" selectionMode="checkbox" placeholder="Filter" @change="changedTree">
    <template #header>
      <div class="m-2 flex">
        <span class="p-input-icon-right mr-2 flex-1">
          <InputText class="w-full" type="text" v-model="searchInput" @input="changedInput" placeholder="Search"/>
          <i class="pi pi-search" />
        </span>
        <Button label="Clear" @click="clearFilter()"/>
      </div>
    </template>
    <template #value>
      <i class="pi pi-filter text-primary" v-if="Object.keys(getFilterTree).length > 0"></i>
      Filter
    </template>  
  </TreeSelect>
</template>

<script>

import TreeSelect from 'primevue/treeselect';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { TreeSelect, InputText, Button },

  computed: {
    ...mapGetters('general', ['getTreeNodes', 'getFilterTree']),

    getFilteredTreeNodes() {
      if(!this.getTreeNodes) return [];
      if(this.searchValue == '') return this.getTreeNodes;
      return this.getTreeNodes.map(x => {
        if(x.key.substring(5).toLowerCase().search(this.searchValue.toLowerCase()) != -1) return x;
        let temp = {...x};
        temp.children = x.children.filter(y => {
          let val = y.key.split(':child:');
          val.shift();
          if(val.join('').toLowerCase().search(this.searchValue.toLowerCase()) != -1) return true;
          return false;
        });
        return temp.children.length > 0 ? temp : null;
      }).filter(x => x != null);
    },
  },

  methods: {
    ...mapActions('general', ["changeFilter", "clearFilter"]),

    changedInput() {
      if(this.inputDebounce) {
        clearInterval(this.inputDebounce);
      }
      this.inputDebounce = setTimeout(() => {
        this.searchValue = this.searchInput;
      }, 300)
    },

    changedTree(val) {
      this.changeFilter(val);
    },
  },

  data() {
    return {
      searchValue: '',
      searchInput: '',
      inputDebounce: null,
    }
  },
}
</script>

<style>

</style>