<template>
  <Dropdown v-model="selectedGroup" :options="getSortedProperties" optionLabel="key" placeholder="Group colors by" @change="changedGroup" :filter="true" filterPlaceholder="Find Property"/>
  <DataTable v-if="grouping" dataKey="group" :value="grouping" class="p-datatable-sm" scrollHeight="flex" columnResizeMode="fit | expand">
    <template #empty>
      Properties not available
    </template>
    <Column field="enabled" class="w-4rem">
      <template #header>
        <ToggleButton offIcon="pi" onIcon="pi pi-check" v-model="selectedAll" @change="changedAllBtn"/>
      </template>
      <template #body="slotProps">
        <ToggleButton offIcon="pi" onIcon="pi pi-check" v-model="slotProps.data.enabled" @change="changedBtn" />
      </template>
    </Column>
    <Column header="Group" field="group" sortable class=""></Column>
    <Column header="Color" field="color" class="w-4rem">
      <template #body="slotProps">
        <CustomColorPicker :initialData="slotProps.data"/>
      </template>
    </Column>
  </DataTable>

</template>

<script>
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import CustomColorPicker from '../3DComponents/CustomColorPicker.vue';
import ToggleButton from 'primevue/togglebutton';
import Column from 'primevue/column';

import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: { Dropdown, DataTable, Column, CustomColorPicker, ToggleButton },

  computed: {
    ...mapState('general', ['grouping', 'group']),
    ...mapGetters('general', ['getProperties']),
  
    getSortedProperties() {
      if(this.getProperties) {
        return Array.from(this.getProperties).sort((a,b) => a.key.localeCompare(b.key));}
      else
        return [];
    }
  },

  methods: {
    ...mapActions('general', ['changeGrouping']),

    changedGroup() {
      this.changeGrouping(this.selectedGroup.key);
    },
    changedBtn() {
      this.selectedAll = this.grouping.every(x => x.enabled);
    },
    changedAllBtn() {
      this.grouping.forEach(x => {
        x.enabled = this.selectedAll;
      })
    },
  },

  data() {
    return {
      selectedAll: true,
      selectedGroup: null,
      groups: [],
      color: 'aaa',
    }
  },

  mounted() {
    this.selectedGroup = this.group;
  }
}
</script>

<style scoped>
.p-dropdown {
  width: 100%;
}
.p-datatable {
  width: 100%;
}
</style>