<template>
  <Group ref="groupRef"> </Group>
</template>

<script>
import {
  Box3,
  Box3Helper,
  BoxHelper,
  LineSegments,
  WireframeGeometry,
  Vector3,
} from "three";
import { TransformControls } from "three/examples/jsm/controls/TransformControls";
import { mapActions, mapGetters } from "vuex";
import { geometriesToMinMax } from "../../helpers/vectors";

export default {
  props: ["scene", "camera", "orbit", "renderer"],

  computed: {
    ...mapGetters("general", ["getSelectedElements"]),
    ...mapGetters("settings", ["isEditMode", "getSelectionMode"]),
  },
  watch: {
    getSelectedElements() {
      this.render();
    },
    isEditMode() {
      this.render();
    },
  },

  methods: {
    ...mapActions("general", ["resetGeometryPosition"]),

    render() {
      this.removeChildren();
      if (this.getSelectedElements.length == 0) return;
      if (this.isEditMode) {
        this.addTransformControl();
      } else {
        this.getSelectedElements.forEach((el) => {
          this.addWireframe(el.geometry);
        });
      }
    },
    removeChildren() {
      this.groupObj.remove(...this.groupObj.children);
      this.groupObj.position.copy(new Vector3(0, 0, 0));
      if (this.control) {
        this.control.detach();
        this.control.dispose();
        this.control = null;
      }
    },
    addWireframe(geometry) {
      const wireframe = new WireframeGeometry(geometry.geometry);
      let line = new LineSegments(wireframe);
      line.position.copy(geometry.mesh.position.clone());
      let box = new BoxHelper(line);
      this.groupObj.add(box);
    },

    addTransformControl() {
      this.control = new TransformControls(
        this.camera,
        this.renderer.domElement
      );
      let vue = this;
      this.control.addEventListener("dragging-changed", (event) => {
        if (vue.getSelectionMode == "area") {
          vue.orbit.enabled = false;
        } else {
          vue.orbit.enabled = !event.value;
        }
      });
      this.scene.add(this.control);
      this.control.attach(this.groupObj);
      let val = geometriesToMinMax(
        this.getSelectedElements.map((x) => x.geometry)
      );
      let box = new Box3(val.min, val.max);
      const helper = new Box3Helper(box, 0xffaa00);
      box.getCenter(this.control.position);

      this.groupObj.add(helper);

      this.getSelectedElements.forEach((el) => {
        el.initialPosition = el.geometry.mesh.position.clone();
      });

      this.control.addEventListener("objectChange", () => {
        this.getSelectedElements.forEach((el) => {
          el.geometry.mesh.position.copy(
            el.initialPosition.clone().add(this.groupObj.position)
          );
        });
      });
      // this.control.position.copy(.geometry.boundingSphere.center);
    },
  },

  data() {
    return {
      groupObj: null,
      control: null,
    };
  },

  beforeUnmount() {
    this.removeChildren();
  },

  mounted() {
    this.groupObj = this.$refs.groupRef.group;
    this.removeChildren();
    this.$nextTick(() => {
      this.render();
    });
  },
};
</script>

<style>
</style>