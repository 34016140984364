import { Color } from "three";
import { Pbi2DGeometryToMinMax } from "../helpers/vectors";
const state = {
  //Theme
  selectionColor: "#ffff00",
  otherColor: "#aaaaaa",

  loadingInfo: null,
  json: null,
  selectedElements: [],
  blacklistedSelections: ["geometry", "Pbi3DGeometry", "Pbi2DGeometry", "TempPbi2DGeometry", "initialPosition"],
  filterGroups: null,
  filterTree: {},
  filter: null,
  grouping: [],
  group: null,
};

const getters = {
  getJson(state) {
    let filtered = state.json;
    if(state.filter) {
      filtered = state.json.filter(x => {
        let valid = true;
        state.filter.forEach(group => {
          if(!group.valid.includes(x[group.group])){
            valid = false;
            return;
          }
        })
        return valid;
      })
    }
    if(state.group) {
      return filtered.filter(x => state.grouping.filter(x => x.enabled).some(y => y.group == x[state.group]) && x.Pbi3DGeometry)
    }
    return filtered;
  },
  getFilterTree(state) {
    return state.filterTree;
  },
  getSelectedElements(state){
    if(!state.json) return [];
    return state.json.filter(x => state.selectedElements.includes(x.ID));
  },
  getProperties(state) {
    if(state.json) {
      let res = {};
      state.json.forEach(obj => Object.assign(res, obj));
      return Object.keys(res).filter(x => !state.blacklistedSelections.includes(x)) .map(x => {return {key: x+''}});
    }
    return null;
  },
  getLoadingInfo(state){
    return state.loadingInfo;
  },
  getFilteredGroups(state) {
    return state.grouping.filter(x => x.enabled);
  },
  getTreeNodes(state) {
    return state.filterGroups;
  }

};


const mutations = {
  changeJson(state, json) {
    state.json = json;
  },
  changeLoadingInfo(state, a) {
    state.loadingInfo = a;
  },
  changeSelected(state, selected) {
    state.selectedElements = selected;
  },
  changeFilter(state, filter) {
    state.filter = filter;
  },
  changeFilterTree(state, filter) {
    state.filterTree = filter;
  },
  handleSelection(state, selection) {
    if(!state.json) return;
    state.json.forEach(el => {
      if(!el.geometry) return;
      if(selection.includes(el.ID)) {
        el.geometry.material.color = new Color(state.selectionColor);
      } else {
        el.geometry.material.color = new Color(state.otherColor);
      }
    });
  },
  handleGroupingColors(state) {
    if(!state.json || !state.group) return;
    state.json.forEach(el => {
      if(!el.geometry) return;
      let group = state.grouping.find(x => x.group == el[state.group]);
      if(group) {
        el.geometry.material.color = new Color('#' + group.color);
      }
    });
  },
  resetShop(state) {
    state.selectedElements = [];
    state.grouping = [];
    state.filteredGroups = [];
    state.group = null;
    state.json = null;
  }
};

const actions = {
  loadJson({commit, dispatch}, json) {
    commit("resetShop");
    if(json.length > 0) {
      commit("changeJson", json);
      commit("changeLoadingInfo", null);
    } else {
      commit("changeLoadingInfo", {text: 'File is empty', value: 0});
    }
    dispatch('createFilterGroups');
    dispatch('clearFilter');

  },
  changeSelected({state, commit}, selected) {
    if(!state.json) return;
    commit("handleSelection", selected);
    commit("changeSelected", selected);
  },
  changeLoadingInfo({commit}, info) {
    commit("changeLoadingInfo", info);
  },
  changeColorInGroup({state}, args) {
    state.grouping = state.grouping.map((x) => {
      if(x.group == args.group) {
        x.color = args.color;
      }
      return x;
    })
  },
  changeGrouping({state, commit}, group) {
    if(!state.json || (!group && !state.group)) return;
    if(group) {
      state.group = group;
    }
    let groups = [];
    state.json.forEach((element) => {
      if(!groups.some(x => x.group == element[state.group])) {
        groups.push({group: element[state.group], color: null, enabled: true});
      }
    })
    this.dispatch('colors/setColorScale', groups.length);
    let colors = this.getters["colors/getColorScale"];
    groups.sort((a,b) => a.group - b.group);
    groups.forEach((el, index) => el.color = colors[index]);
    state.grouping = groups;
    commit("handleGroupingColors");
  },

  calculate2D({state, commit}, viewport) {
    if(!state.json) return;
    let minMax = Pbi2DGeometryToMinMax(state.json.filter(x => x.Pbi2DGeometry).map(x => x.Pbi2DGeometry));
    const width = minMax.max.x - minMax.min.x;
    const height = minMax.max.y - minMax.min.y;
    const scale = Math.min(viewport.width / width, viewport.height / height) * 0.6; 
    const xoffset = (viewport.width - width*scale) / 2;
    const yoffset = (viewport.height - height*scale) / 2;
    let tmp = state.json;
    tmp.forEach(el => {
      if(!el.Pbi2DGeometry) return;
      let points = [];
      el.Pbi2DGeometry.Points.forEach(_point => {
        let point = [].concat(_point);
        point[0] -= minMax.min.x;
        point[1] -= minMax.min.y;
  
        point[0] *= scale;
        point[1] *= scale;
  
        point[0] += xoffset;
        point[1] += yoffset;
  
        point[1] = viewport.height - point[1];
        points.push(point);
      })
      el.TempPbi2DGeometry = {
        Points: points,
        Radius: el.Pbi2DGeometry.Diameter * scale,
        Length: el.Pbi2DGeometry.Length * scale,
        Width: el.Pbi2DGeometry.Width * scale,
      }
    })
    commit('changeJson', tmp);
  },

  addGeometry({state}, info) {
    let el = state.json.find(x => x.ID == info.id);
    if(el) {
      el.geometry = info.geometry;
      el.initialPosition = info.geometry.mesh.initialPosition;
    }
  },
  createFilterGroups({state}) {
    let root = [];
    let keys = this.getters["general/getProperties"].map(x => { return {key: x.key, words: []} }).sort((a,b) => a.key.localeCompare(b.key));
    keys.forEach(key => {
      state.json.forEach(el => {
        let word = el[key.key];
        if(!key.words.includes(word)) {
          key.words.push(word+'')
        }
      })
      root.push({
        key: ':key:'+key.key,
        label: key.key,
        children: key.words.sort((a,b) => a.localeCompare(b)).map(x => { return {
          key: key.key+':child:'+x,
          label: x,
        } })
      })
    })
    state.filterGroups = root;
  },

  changeFilter({commit}, filter) {
    commit('changeFilterTree', filter)
    let keys = Object.keys(filter)
    let validGroups = keys.filter(x => x.substring(0, 5) == ":key:" && filter[x].partialChecked ).map(x => {return {group: x.substring(5), valid: []}});
    validGroups.forEach(group => {
      let valids = [];
      keys.forEach(x => {
        let k = x.split(':child:')
        if(k[0] == group.group) {
          k.shift();
          valids.push(k.join(''));
        }
      })
      group.valid = valids;
    });
    commit('changeFilter', validGroups);
  },
  clearFilter({commit}) {
    commit('changeFilterTree', {});
    commit('changeFilter', null); 
  }
};

const general = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default general;