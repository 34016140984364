import { createWebHistory, createRouter } from "vue-router";
import RenderView from '../components/RenderView.vue';
import View2D from '../components/View2D.vue';

const routes = [
  { name: '3D', path: '/', component: RenderView},
  { name: '2D', path: '/2d/', component: View2D},
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;