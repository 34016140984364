const state = {
  editMode: false,
  selectionMode: "none",
};
const getters = {
  isEditMode(state) {
    return state.editMode;
  },
  getSelectionMode(state) {
    return state.selectionMode;
  },
};
const mutations = {
  changeEditMode(state, edit) {
    state.editMode = edit;
  },
  changeSelectionMode(state, mode) {
    state.selectionMode = mode;
  },
};
const actions = {
  changeEditMode({ commit }, edit) {
    commit("changeEditMode", edit);
  },
  changeSelectionMode({ commit }, mode) {
    commit("changeSelectionMode", mode);
  },
};

const settings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
export default settings;
