<template>
  <ColorPicker v-model:modelValue="value" />
</template>

<script>
import ColorPicker from 'primevue/colorpicker';
import { mapActions } from 'vuex';
export default {
  components: { ColorPicker },

  props: ['initialData'],

  watch: {
    value(val) {
      this.changeColorInGroup({color: val, group: this.initialData.group});
    }
  },

  methods: {
    ...mapActions('general', ['changeColorInGroup']),
  },

  data() {
    return {
      value: '',
    }
  },

  mounted() {
    this.value = this.initialData.color;
  },
  updated() {
    this.value = this.initialData.color;
  }
}
</script>

<style>

</style>