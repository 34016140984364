<template>
<div class="wrapper">
  <div class="layout grid grid-nogutter">
    <div class="col-12">
      <MenuBar/>
    </div>
    <div class="col-12 innerLayout">
      <div class="grid grid-nogutter h-full">
        <div class="col-9 h-full relative">
          <Loader />
          <RouterView />
        </div>
        <div class="col-3 h-full">
          <Sidebar/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import Sidebar from './components/Sidebar/Sidebar.vue';
import MenuBar from './components/Menu/MenuBar.vue';
import Loader from './components/Loader.vue';
export default {
  name: "App",

  components: { Sidebar, MenuBar, Loader },
}
</script>

<style>
body {
  margin: 0;
}
</style>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: var(--surface-a);
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  .wrapper {
    height: 100vh;
    overflow: hidden;
  }
  .layout {
    height: 100%;
    align-content: flex-start;
  }
  .innerLayout {
    height: calc(100% - 64px);
  }
}
</style>
