
const state = {
  
};
const getters = {
  
};
const mutations = {

};
const actions = {
  
};


const view2D = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default view2D;