<template>
  <Group ref="group">
    <CustomObject v-for="line in getGeometry" :key="line.troisIndex" :obj="line" @clickedElement="click" :initialColor="getColor(line)"/>
  </Group>
</template>

<script>
import CustomObject from './CustomObject.vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {CustomObject},

  computed: {
    ...mapState('general', ['group', 'grouping', 'otherColor']),
    ...mapGetters('general', ['getFilteredGroups','getJson', 'getSelectedElements']),
    ...mapGetters('settings', ['getSelectionMode']),

    getGeometry() {
      return this.geometry.filter(x => x.Pbi3DGeometry);
    },
  },

  watch: {
    getJson(newVal, oldVal) {
      this.loadGeometry(oldVal);
    }
  },

  methods: {
    ...mapActions('general', ['changeSelected', 'changeLoadingInfo']),
    ...mapActions('view3D', ['saveTargetPosition','calculatePositions']),

    loadGeometry(oldVal) {
      // keep the same values
      let tempNewGeo = this.getJson;
      this.changeLoadingInfo(null);

      // remove values
      let removeArr = oldVal.filter(x => !tempNewGeo.find(y => y.troisIndex == x.troisIndex));
      let addArr = tempNewGeo.filter(x => !oldVal.find(y => y.troisIndex == x.troisIndex));
      if(removeArr.length == 0) {
        this.loadNewGeometry(addArr);
        return;
      }
      let num = 0;
      removeArr.forEach(el => {
        setTimeout(() => {
          this.geometry.splice(this.geometry.findIndex(x => x.troisIndex == el.troisIndex), 1);
          this.changeLoadingInfo({text: "Removing "+num+ " of "+ removeArr.length + " old models...", value: num++ / removeArr.length});
          if(num == removeArr.length) {
            this.loadNewGeometry(addArr);
          }
        }, 10);
      })
    },
    loadNewGeometry(addArr) {
      if(addArr.length == 0) {
        this.changeLoadingInfo(null);
        return;
      }
      let num = 0;
      addArr.forEach(el => { 
        setTimeout(() => {
          this.geometry.push(el)
          this.changeLoadingInfo({text: "Adding "+num+ " of "+ addArr.length + " new models...", value: num++ / addArr.length});
          if(num == addArr.length) {
            this.changeLoadingInfo(null);
            this.calculations();
          }
        }, 10)
      })
    },

    getColor(el) {
      if(this.group) {
        return '#' + this.grouping.find(x => x.group == el[this.group]).color
      }
      return this.otherColor;
    },

    click(...e) {
      if(!this.clickedEl) {
        if(event.altKey) {
          this.saveTargetPosition(e[0].component.mesh.position.clone().add(e[0].component.geometry.boundingSphere.center));
        } else {
          if(this.getSelectionMode == 'multiple') {
            if(this.getSelectedElements.some(x => x.ID == e[1].ID)) {
              this.changeSelected(this.getSelectedElements.filter(x => x.ID != e[1].ID).map(x => x.ID));
            } else {
              this.changeSelected(this.getSelectedElements.map(x => x.ID).concat(e[1].ID));
            }
          } else if(this.getSelectionMode == 'single') {
            if(this.getSelectedElements.some(x => x.ID == e[1].ID) && this.getSelectedElements.length == 1) {
              this.changeSelected([]);
            } else {
              this.changeSelected([e[1].ID]);
            }
          }
          }
        //this.control.worldPosition = new Vector3(1,1,1);
        //this.control.worldPosition.copy(e[0].component.geometry.boundingSphere.center);
        this.clickedEl = e[0].component;
        this.$nextTick(() => {
          this.clickedEl = null;
        })
      }
    },
    async calculations() {
      this.calculatePositions();
    },
  },

  data() {
    return {
      geometry: [],
      clickedEl: null,
      enabledClick: true,
    }
  },

  mounted() {
    this.loadGeometry([]);
  },

}
</script>

<style>

</style>