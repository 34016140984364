import { interpolateRgb } from 'd3-interpolate';

function RGBToHex(rgb) {
  let sep = rgb.indexOf(",") > -1 ? "," : " ";
  rgb = rgb.substr(4).split(")")[0].split(sep);
  let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);
  if (r.length == 1)
    r = "0" + r;
  if (g.length == 1)
    g = "0" + g;
  if (b.length == 1)
    b = "0" + b;
  return "" + r + g + b;
}

const state = {
  colorMode: 'random',
  colorAsc: true,
  interpolate1: '000000',
  interpolate2: 'ffffff',
  colors: [],
};
const getters = {
  getColorScale(state) {
    if(state.asc)
      return state.colors.slice().reverse();
    return state.colors;
  },
};
const mutations = {
  setColorScale(state, colors) {
    state.colors = colors;
  },
  setColorMode(state, mode) {
    state.colorMode = mode;
  },
  setColorAsc(state, asc) {
    state.colorAsc = asc;
  },
  setInterpolation(state, interpolate) {
    state.interpolate1 = interpolate[0];
    state.interpolate2 = interpolate[1];
  },
};
const actions = {
  setColorScale({state, commit}, length) {
    let temp = [];
    if(state.colorMode == 'random') {
      for(let i = 0; i < length; i++) {
        temp.push((Math.random() * 0xfffff * 1000000).toString(16).slice(0,6));
      }
    } else if(state.colorMode == 'interpolate') {
      const interpolate = interpolateRgb('#'+state.interpolate1, '#'+state.interpolate2);
      for(let i = 0; i <= length-1; i++) {
        temp.push(RGBToHex(interpolate(i / (length-1))));
      }
    }
    commit('setColorScale', temp);
  },
  setColorSettings({commit}, opt) {
    commit('setColorMode', opt.mode);
    commit('setColorAsc', opt.asc);
    commit('setInterpolation', opt.interpolate);
    this.dispatch('general/changeGrouping');
  }
};


const colors = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
export default colors;