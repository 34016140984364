<template>
<div class="sidebar">
  <TabMenu v-model:activeIndex="activeIndex" :model="tabItems"></TabMenu>
  <div class="tabs">
    <PropertiesTab v-if="activeIndex === 0" />
    <ColorCodeTab v-if="activeIndex === 1" />
    <IoTTab v-if="activeIndex === 2" />
  </div>
</div>
</template>

<script>
import PropertiesTab from './PropertiesTab.vue';
import ColorCodeTab from './ColorCodeTab.vue';
import IoTTab from './IoTTab.vue';

import TabMenu from 'primevue/tabmenu';
export default {
  name: 'Sidebar',

  components: { TabMenu, PropertiesTab, ColorCodeTab, IoTTab },

  data() {
    return {
      tabItems: [
        { label: 'Properties' },
        { label: 'Color Code' },
        { label: 'IoT' },
      ],
      activeIndex: 0,
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  background: var(--surface-a);
  height: calc(100vh - 64px) !important;
  position: relative;
  .tabs {
    padding: 5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;
    height: calc(100% - 42px);
    width: 100%;
  }
}
</style>