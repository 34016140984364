<template>
  <svg ref="svg" id="svg">
    <g class="container">
      <template v-for="el in getGeometry" :key="el.ID">
        <KobiRectangle @click="clickedEl(el)" :data="el" v-if="el.Pbi2DGeometry && el.Pbi2DGeometry.PbiType=='KobiRectangle'" />
        <KobiPolygon @click="clickedEl(el)" :data="el" v-if="el.Pbi2DGeometry && el.Pbi2DGeometry.PbiType=='KobiPolygon'" />
        <KobiSphere @click="clickedEl(el)" :data="el" v-if="el.Pbi2DGeometry && el.Pbi2DGeometry.PbiType=='KobiSphere'" />
      </template>
    </g>
  </svg>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import KobiRectangle from './2DComponents/KobiRectangle.vue';
import KobiPolygon from './2DComponents/KobiPolygon.vue';
import KobiSphere from './2DComponents/KobiSphere.vue';

import * as d3 from 'd3';

export default {
  computed: {
    ...mapState('general', ['group', 'grouping', 'filter']),
    ...mapGetters('general', ['getFilteredGroups','getJson', 'getSelectedElements']),
    ...mapGetters('settings', ["getSelectionMode"]),

    getGeometry() {
      if(this.group) {
        return this.json.filter(x => this.getFilteredGroups.some(y => y.group == x[this.group]));
      }
      return this.json;
    },
  },

  watch: {
    getJson() {
      this.calculate();
    },
  },

  methods: {
    ...mapActions('general', ['calculate2D','changeSelected', 'changeTargetLocation']),

    clickedEl(obj) {
      if(this.getSelectionMode=='multiple') {
        if(this.getSelectedElements.some(x => x.ID == obj.ID)) {
          this.changeSelected(this.getSelectedElements.filter(x => x.ID != obj.ID).map(x => x.ID));
        } else {
          this.changeSelected(this.getSelectedElements.map(x => x.ID).concat(obj.ID));
        }
      } else if(this.getSelectionMode=='single') {
        if(this.getSelectedElements.some(x => x.ID == obj.ID)) {
          this.changeSelected([]);
        } else {
          this.changeSelected([obj.ID]);
        }
      }
    },

    calculate() {
      this.calculate2D({
        width: this.$refs.svg.clientWidth,
        height: this.$refs.svg.clientHeight,
      });
      this.json = this.getJson;

    },

  },

  components: { KobiRectangle, KobiPolygon, KobiSphere },
  data() {
    return {
      json: [],
      zoomSettings: null,
      container: null,
    }
  },
  mounted() {
    this.container = d3.select('.container');
    this.zoomSettings = d3.zoom()
    .on("zoom", (attr) => {
      this.container.attr("transform", attr.transform)
    });
    d3.select("svg").call(this.zoomSettings);
    this.calculate();
  }
}
</script>

<style>
#svg {
  width: 100%;
  height: 100%;
  background: var(--surface-c);
}
</style>