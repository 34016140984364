import { Vector3 } from "three";

const state = {
  cameraPosition: new Vector3(0, 0, 0),
  targetPosition: new Vector3(0, 0, 0),
  first: true,
};
const getters = {
  getCameraPosition(state) {
    return state.cameraPosition;
  },
  getTargetPosition(state) {
    return state.targetPosition;
  },
};
const mutations = {
  changeCameraPosition(state, pos) {
    state.cameraPosition = pos;
  },
  changeTargetPosition(state, pos) {
    state.targetPosition = pos;
  },
};
const actions = {
  saveCameraPosition({ commit }, pos) {
    commit("changeCameraPosition", pos);
  },
  saveTargetPosition({ commit }, pos) {
    commit("changeTargetPosition", pos);
  },
  calculatePositions({ state, commit }) {
    let json = this.getters['general/getJson'];
    if(!json) return;
    let minVector = new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE);
    let maxVector = new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE);
    
    json.forEach((child) => {
      if(child.geometry) {
        let bound = child.geometry.geometry.boundingBox;
        minVector.min(bound.min);
        maxVector.max(bound.max);
      }
    });

    let width = maxVector.clone().sub(minVector).clone();
    let targetPos = (minVector.clone().add(width.clone().multiplyScalar(0.5)));
    let cameraPos = targetPos.clone().add(width.clone());
    if(state.first) {
      commit('changeTargetPosition', targetPos);
      commit('changeCameraPosition', cameraPos)
    }
    state.first = false;
  },
  resetShop({state}) {
    state.first = true;
  }
};

const view3D = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
export default view3D;
