import { createApp } from 'vue'
import App from './App.vue'
import { TroisJSVuePlugin } from 'troisjs';
import PrimeVue from 'primevue/config';
import store from './store/index';
import router from './router';

import 'primeflex/primeflex.css' 
import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css'       //theme
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'                           //icons

const app = createApp(App);
app.use(TroisJSVuePlugin);
app.use(store);
app.use(PrimeVue);
app.use(router);

app.mount('#app');
