
<script>
import { SelectionBox } from "three/examples/jsm/interactive/SelectionBox";
import { SelectionHelper } from "three/examples/jsm/interactive/SelectionHelper.js";
import { Vector2 } from "three";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["camera", "scene", "renderer"],

  computed: {
    ...mapGetters("settings", ["getSelectionMode", "isEditMode"]),
  },

  watch: {
    getSelectionMode() {
      this.handleArea();
    },

    isEditMode() {
      this.handleArea();
    }
  },

  methods: {
    ...mapActions("general", ["changeSelected"]),

    handleArea() {
      if(this.getSelectionMode == 'area' && !this.isEditMode) {
        document.documentElement.style.setProperty('--areaMode', 'block');
      } else {
        document.documentElement.style.setProperty('--areaMode', 'none');
      }
    },

    validEvent(event) {
      return this.getSelectionMode == "area" && event.clientX < this.size.x && (event.clientY-64) > 0 && !this.isEditMode;
    },

    mouseDown(event) {
      if (!this.validEvent(event)) return;
      this.renderer.getSize(this.size)
      this.startMove = true;
      this.selectionBox.startPoint.set(
        (event.clientX / this.size.x) * 2 - 1,
        -((event.clientY-64) / this.size.y) * 2 + 1,
        0.5
      );
    },

    mouseMove(event) {
      if (!this.validEvent(event) || !this.startMove) return;
      if (this.helper.isDown) {
        this.selectionBox.endPoint.set(
          (event.clientX / this.size.x) * 2 - 1,
          -((event.clientY-64) / this.size.y) * 2 + 1,
          0.5
        );
      }
    },

    mouseUp(event) {
      if (!this.validEvent(event) || !this.startMove) return;
      this.selectionBox.endPoint.set(
        (event.clientX / this.size.x) * 2 - 1,
        -((event.clientY-64) / this.size.y) * 2 + 1,
        0.5
      );
      this.startMove = false;
      let distance = this.selectionBox.startPoint.distanceTo(this.selectionBox.endPoint);
      if(distance < 0.01) return;
      this.selected = this.selectionBox.select();
      this.changeSelected(this.selected.map((mesh) => mesh.userData.id));
    },
  },

  data() {
    return {
      selectionBox: null,
      helper: null,
      selected: [],
      size: new Vector2(),
      startMove: false,
    };
  },

  mounted() {
    this.selectionBox = new SelectionBox(this.camera, this.scene);
    this.helper = new SelectionHelper(
      this.selectionBox,
      this.renderer,
      "selectBox"
    );
    this.renderer.getSize(this.size);

    this.handleArea();

    document.addEventListener("pointerdown", this.mouseDown);
    document.addEventListener("pointermove", this.mouseMove);
    document.addEventListener("pointerup", this.mouseUp);
  },
};
</script>

<style>
.selectBox {
  border: 1px solid #55aaff;
  background-color: rgba(75, 160, 255, 0.3);
  position: fixed;
  display: var(--areaMode);
}
</style>