const pako = require("pako");

let gzipToJson = (encoded) => {
  
  let binary = atob(encoded);
  let bytes = Uint8Array.from(binary, (c) => c.charCodeAt(0));
  // You appended the length at the start of gzBuffer, so you need to remove those bytes
  bytes = bytes.slice(4);

  // inflate the message & convert it to a string
  let inflated = pako.inflate(bytes);
  return JSON.parse(new TextDecoder().decode(inflated))[0];
}

export default gzipToJson;